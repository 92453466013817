<template>
  <Modal
    :open="open"
    data-cy="make-model-modal"
    enable-prevent-body-scroll
    full-screen-on-tablet
    height="100vh"
    name="make-model-browser"
    self-contained
    sticky-footer
    width="70rem"
    :class="[
      'make-model-modal',
      {
        'make-model-modal--home-mode': home,
        'make-model-modal--make-mode': searchBy === 'make',
        'make-model-modal--model-mode': searchBy === 'model',
      },
    ]"
    @close="() => $emit('close')"
  >
    <template v-slot:header>
      <div
        class="make-model-modal__header"
        :class="{ 'make-model-modal__header--full-grid': !isSizeMUp && showSearcher }"
      >
        <div v-if="isSizeMUp || !isSizeMUp" class="make-model-modal__header__title">
          <h2 :class="['f-size-18', { 'f-700': searchBy === 'make' }]" v-html="title" />
        </div>

        <div class="make-model-modal__header__actions">
          <div
            v-if="searchBy === 'make'"
            id="search"
            :class="[
              'make-model-modal__header__actions__search',
              {
                'make-model-modal__header__actions__search--collapsed': !showSearcher,
              },
            ]"
          >
            <Icon
              name="search"
              size="1.5rem"
              class="make-model-modal__header__actions__search__icon"
              @click.native="toggleSearchBar(true)"
            />
            <input
              :id="`${uniqueId}_search_input`"
              v-model="searchTerm"
              type="text"
              class="search-input make-model-modal__header__actions__search__input"
              @keyup="(event) => searchMake(event)"
            />
            <Icon
              name="cancel"
              size="1.25rem"
              class="make-model-modal__header__actions__search__clear"
              filled
              @click.native="toggleSearchBar(false)"
            />
          </div>
        </div>
      </div>
    </template>

    <div
      v-if="searchBy === 'make' && (!searchTerm || (searchTerm && searchResults?.length))"
      class="make-model-modal__makes"
    >
      <div
        v-for="results in makeResultsByLetter"
        :key="results.key"
        class="make-model-modal__makes__results"
      >
        <div class="make-model-modal__makes__results__letter">
          <span class="make-model-modal__makes__results__letter__text">
            {{ results.letterGroup }}
          </span>
        </div>
        <div class="make-model-modal__makes__results__makes">
          <div
            v-for="make in results.makesByLetter"
            :key="make.key"
            class="make-model-modal__makes__results__makes__item"
            data-cy="make-item"
            @click="onSelectMake(make)"
          >
            <div class="make-model-modal__makes__results__makes__item__info">
              <img
                loading="lazy"
                :src="make?.logo?.file"
                alt="Logo"
                class="make-model-modal__makes__results__makes__item__info__logo"
              />
              <p class="make-model-modal__makes__results__makes__item__info__name">
                {{ make.name }}
              </p>
            </div>
            <div class="make-model-modal__makes__results__makes__item__units">
              <span class="make-model-modal__makes__results__makes__item__units__number">
                {{ setAvailableUnits(make) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="searchBy === 'make'" class="make-model-modal__no-results">
      <p
        class="make-model-modal__no-results__search-term"
        v-html="$t('make_model_browser.no_results_for_make', { searchTerm })"
      />

      <div class="make-model-modal__no-results__info">
        <p class="make-model-modal__no-results__info__emoji s-m-0">🤔</p>
        <p class="make-model-modal__no-results__info__no-results s-m-0 s-mb-4">
          {{ $t('make_model_browser.search_makes_no_results') }}
        </p>
        <p class="make-model-modal__no-results__info__try-another s-m-0">
          {{ $t('make_model_browser.search_try_other_make') }}
        </p>
      </div>
    </div>
    <div v-if="searchBy === 'model'" class="make-model-modal__models">
      <div
        v-for="model in modelResultsByMake"
        :key="model.key"
        class="make-model-modal__models__model"
        data-cy="model-item"
        @click="onSelectModel(model)"
      >
        <div class="make-model-modal__models__model__content">
          <figure class="make-model-modal__models__model__content__figure">
            <img
              loading="lazy"
              :src="
                model?.hero?.file ||
                model?.vehicles[0]?.version?.hero?.file ||
                model?.vehicles[0]?.hero?.file
              "
              alt="vehicle image"
              class="make-model-modal__models__model__content__figure__image"
            />
          </figure>
          <div class="make-model-modal__models__model__content__info">
            <p class="make-model-modal__models__model__content__info__number">
              {{ setAvailableUnits(model) }}
            </p>
            <p class="make-model-modal__models__model__content__info__name">
              {{ model.name }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
// TODO: check and refactor logic

// Helpers & mixins
import checkViewportSize from '@/mixins/checkViewportSize'

// Components
import Icon from '@/components/Icon.vue'
import Modal from '@/components/Modal.vue'

export default {
  components: {
    Icon,
    Modal,
  },
  mixins: [checkViewportSize],
  props: {
    home: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchBy: {
      type: String,
      required: true,
      default: 'make',
    },
    vehicleType: {
      type: String,
      required: true,
      default: 'car',
    },
    title: {
      type: String,
      required: true,
      default: 'Elige',
    },
    modelsMake: {
      type: Object,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    open: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      searchResults: [],
      searchTerm: '',
      showSearcher: false,
      vehiclesNumberByModel: [],
    }
  },
  computed: {
    makeResultsByLetter() {
      const dataForResults = this.searchResults.length === 0 ? this.data : this.searchResults

      const data = dataForResults?.reduce((r, e) => {
        const letterGroup = e.name[0]
        if (!r[letterGroup]) r[letterGroup] = { letterGroup, makesByLetter: [e] }
        else r[letterGroup].makesByLetter.push(e)

        return r
      }, {})

      const sortedResult = Object.keys(data)
        .sort()
        .map((key) => data[key])

      return sortedResult
    },
    modelResultsByMake() {
      const filteredModels = this.data.filter((model) => model.make.name === this.modelsMake?.name)
      this.setVehiclesNumberByModel(filteredModels)
      const uniqueData = [...new Map(filteredModels.map((model) => [model.name, model])).values()]

      return uniqueData
    },
  },
  methods: {
    closePressingKey(event) {
      if (event.key === 'Escape') {
        event.stopPropagation()
        this.$emit('close')
      }
    },
    preventBodyScroll() {
      document.body.style.right = 0
      document.body.style.left = 0
      document.body.style.bottom = 0
      document.body.style.top = `-${window.scrollY}px`
      document.body.style.position = 'fixed'
    },
    releaseBodyScroll() {
      const scrollY = document.body.style.top
      document.body.style.position = ''
      document.body.style.top = ''
      document.body.style.right = ''
      document.body.style.left = ''
      document.body.style.bottom = ''
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    },
    toggleSearchBar(state) {
      this.showSearcher = state

      if (state) {
        document.getElementById(`${this.uniqueId}_search_input`).focus()
      } else {
        this.searchResults = []
        this.searchTerm = ''
      }
    },
    searchMake(evt) {
      this.searchTerm = evt.target.value
      this.searchResults = this.data.filter((make) =>
        make?.name?.toLowerCase()?.startsWith(evt.target.value.toLowerCase()),
      )
    },
    onSelectMake(make) {
      this.searchResults = []
      this.showSearcher = false
      this.$emit('selected-make', make)
      this.$emit('close')
    },
    onSelectModel(model) {
      model.availableUnits = this.setAvailableUnits(model)
      this.$emit('selected-model', model)
      this.$emit('close')
    },
    setVehiclesNumberByModel(filteredModels) {
      const vehiclesUnits = []

      filteredModels.forEach(function (model) {
        const modelExists = vehiclesUnits.some((el) => el.name === model.name)
        // If the model name does NOT exist in the vehiclesUnits array, add the model object and available Units
        if (!modelExists) {
          vehiclesUnits.push({
            name: model.name,
            availableUnits: model.availableUnits,
          })
        } else {
          // If the model name exists in the vehiclesUnits array, sum the existing available Units to the new
          const objIndex = vehiclesUnits.findIndex((obj) => obj.name === model.name)
          vehiclesUnits[objIndex].availableUnits =
            vehiclesUnits[objIndex].availableUnits + model.availableUnits
        }
      })

      this.vehiclesNumberByModel = vehiclesUnits
    },
    setAvailableUnits(info) {
      if (this.searchBy === 'model') {
        const infoIndex = this.vehiclesNumberByModel.findIndex((obj) => obj.name === info.name)
        return this.vehiclesNumberByModel[infoIndex].availableUnits
      } else {
        return info.publishableUnits
      }
    },
    onOpen() {
      this.preventBodyScroll()
      document.addEventListener('keydown', this.closePressingKey)
    },
  },
  watch: {
    open: {
      handler() {
        if (this.open) {
          this.onOpen()
        } else {
          this.releaseBodyScroll()
          document.removeEventListener('keydown', this.closePressingKey)

          if (!this.searchTerm) {
            this.showSearcher = false
          }
        }
      },
      deep: true,
    },
  },
  created() {
    this.uniqueId = this._uid
  },
  mounted() {
    if (this.open) {
      this.onOpen()
    }
  },
}
</script>

<style lang="scss" scoped>
.make-model-modal {
  :deep(.modal__header) {
    display: flex;
    align-items: center;
  }

  :deep(.modal) {
    background-color: $c-secondary-background-color;
  }

  &__header {
    display: grid;
    grid-template-columns: 8fr 1fr;
    align-items: center;
    gap: 1rem;

    @include size-m-up {
      gap: 1.5rem;
    }

    @include size-l-up {
      grid-template-columns: 1fr 1fr;
    }

    &--complete-grid {
      grid-template-columns: 1fr;
    }

    &__title {
      display: flex;
      flex: 1 0 0;
      align-items: center;
      gap: 0.5rem;

      h2 {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        text-overflow: ellipsis;
        white-space: nowrap;

        :deep(.models-number) {
          display: inline-block;
          min-width: 1.25rem;
          height: 1.25rem;
          padding: 0 0.3125rem;
          border-radius: 1.3125rem;
          background: $highlight-1;
          color: $c-white;
          font-family: 'Inter', Arial, Helvetica, sans-serif;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1.25rem;
          text-align: center;
        }

        :deep(.make) {
          color: $highlight-1 !important;
        }
      }
    }

    &__actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: end;
      width: 100%;
      padding-right: 1rem;

      @include size-l-up {
        padding-right: 1.5rem;
      }

      :deep(.icon) {
        cursor: pointer;
      }
    }
  }

  &__makes {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    gap: 1rem;

    @include size-m-up {
      gap: 1.5rem;
    }

    &__results {
      display: grid;
      grid-template-columns: 1rem 1fr;
      flex-direction: row;
      align-items: flex-start;
      align-self: stretch;
      justify-content: center;
      gap: 0.5rem;

      @include size-m-up {
        gap: 0.75rem;
      }

      &__letter {
        display: flex;
        align-items: center;
        gap: 0.625rem;

        &__text {
          color: #000;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: 1.25rem;
        }
      }

      &__makes {
        display: grid;
        grid-column-gap: 1.5rem;
        grid-row-gap: 1rem;
        grid-template-columns: 1fr;
        width: 100%;

        @include size-m-up {
          grid-template-columns: 1fr 1fr;
        }

        &__item {
          display: flex;
          flex: 1 0 0;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          height: 3.5rem;
          padding: 0.25rem 0.5rem;
          transition: box-shadow 0.3s ease;
          border: 0 solid #6453a6;
          border-radius: 0.25rem;
          background: $c-white;
          box-shadow: $c-elevation-1;
          cursor: pointer;
          gap: 0.5rem;

          &:hover {
            box-shadow: $c-elevation-2;
            cursor: pointer;
          }

          > * {
            position: none;
          }

          &__info {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0.5rem;

            &__logo {
              width: 2rem;
              height: 2rem;
              margin: 0.25rem;
            }

            &__name {
              display: flex;
              align-items: center;
              margin: 0;
              color: $c-neutral-900;
              font-size: 1rem;
              font-style: normal;
              font-weight: 500;
              line-height: 1.25rem;
            }
          }

          &__units {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 20px;
            height: 20px;
            padding: 0 5px;
            border-radius: 21px;
            background: $highlight-1;
            font-family: 'Inter', Arial, Helvetica, sans-serif;
            text-align: center;

            &__number {
              color: $c-white;
              font-size: 0.875rem;
              font-style: normal;
              font-weight: 600;
              letter-spacing: -0.05rem;
              line-height: 1.25rem;
              text-align: center;
            }
          }
        }
      }
    }
  }

  &__no-results {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 0.75rem;

    &__search-term {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      margin: 0;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5rem;
      gap: 0.25rem;

      :deep(.vehicles-number) {
        display: inline-block;
        min-width: 1.25rem;
        height: 1.25rem;
        padding: 0 0.3125rem;
        border-radius: 1.3125rem;
        background: $highlight-1;
        color: $c-white;
        font-family: 'Inter', Arial, Helvetica, sans-serif;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.25rem;
        text-align: center;
      }

      :deep(.search-term) {
        color: $highlight-1 !important;
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      &__emoji {
        margin: 0;
        font-size: 2.25rem;
        line-height: 2.5rem;
      }

      &__no-results,
      &__try-another {
        margin: 0;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 300;
        line-height: 1.75rem;
      }
    }
  }

  &__models {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-self: stretch;
    gap: 20px;

    @include size-m-up {
      grid-template-columns: 1fr 1fr 1fr;
      gap: 24px;
    }

    &__model {
      transition: box-shadow 0.3s ease;
      border-radius: 4px;
      background: #fff;

      &:hover {
        box-shadow: $c-elevation-2;
        cursor: pointer;
      }

      &__content {
        display: flex;
        position: relative;
        flex: 1 0 0;
        flex-direction: column;
        align-items: center;
        height: fit-content;
        margin-bottom: 54px;
        border-radius: 4px;
        gap: 4px;

        &__figure {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          align-self: stretch;
          margin: 0;
          object-fit: cover;

          &__image {
            max-width: 100%;
            border-radius: 4px 4px 0 0;
            object-fit: cover;
            aspect-ratio: 16/9;
          }
        }

        &__info {
          display: flex;
          position: absolute;
          bottom: -54px;
          flex-direction: column;
          align-items: center;
          width: 100%;
          padding: 4px 8px 16px;
          border-radius: 0 0 4px 4px;
          gap: 4px;

          &__number {
            min-width: 20px;
            height: 20px;
            margin: 0;
            padding: 0 5px;
            border-radius: 21px;
            background: #524589;
            color: #fff;
            font-family: 'Inter', Arial, Helvetica, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            letter-spacing: -0.8px;
            line-height: 20px;
            text-align: center;
          }

          &__name {
            margin: 0;
            color: #1e1932;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            letter-spacing: -0.4px;
            line-height: 24px;
            text-align: center;
          }
        }
      }
    }
  }
}

.make-model-modal__header__actions__search {
  display: flex;
  position: relative;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  height: 2.75rem;
  padding: 0.625rem 0.75rem;
  overflow: hidden;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  border: 0.125rem solid $c-primary-500;
  border-radius: 0.5rem;
  background: $c-neutral-300;
  cursor: pointer;
  gap: 0.5rem;
  direction: ltr;

  @include size-m-up {
    width: 40vw;
  }

  @include size-xxl-up {
    width: 100%;
  }

  .make-model-modal__header__actions__search__input {
    width: 100%;
    border: none;
    outline: none;
    background: unset;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    cursor: pointer;
  }

  .make-model-modal__header__actions__search__icon {
    left: 0;
    width: 1.25rem;
    height: 1.25rem;
    transition: left 2s ease;
    transition-duration: 0.4s;
    cursor: pointer;
    direction: ltr;
  }

  .make-model-modal__header__actions__search__clear {
    width: 1.25rem;
    height: 1.25rem;
    font-size: 1.25rem;
    cursor: pointer;
  }

  &--collapsed {
    max-width: 3rem;
    transition-duration: 0.4s;
    border: 0.125rem solid transparent;
    background-color: unset;
    direction: rtl;

    .make-model-modal__header__actions__search__icon {
      right: 0;
      transition-duration: 0.4s;
      direction: rtl;
    }

    .make-model-modal__header__actions__search__input {
      display: none;
    }
    .make-model-modal__header__actions__search__clear {
      display: none;
    }
  }
}
</style>
