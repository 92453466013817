import { gql } from 'graphql-tag'

// (home) Make-model form

export const makes = gql`
  query makes($vehicleTypeIn: [String!]) {
    makes(onlyAvailables: true, active: true, vehicleTypeIn: $vehicleTypeIn) {
      nodesCount
      nodes {
        publishableUnits
        name
        slug
        logo {
          file
        }
      }
    }
  }
`
export const models = gql`
  query models($vehicleTypeIn: [String!]) {
    models(
      onlyAvailables: true
      active: true
      vehicleTypeIn: $vehicleTypeIn
      vehicles: { statusIn: "available" }
    ) {
      nodesCount
    }
  }
`

export const modelsByMake = gql`
  query modelsByMake($vehicleTypeIn: [String!], $makeSlugLike: String!) {
    models(
      onlyAvailables: true
      active: true
      vehicleTypeIn: $vehicleTypeIn
      make: { slugLike: $makeSlugLike }
      vehicles: { statusIn: "available" }
    ) {
      nodesCount
      nodes {
        availableUnits
        name
        slug
        make {
          name
          slug
        }
        hero {
          file
        }
        vehicles(first: 1) {
          uid
          hero {
            file
          }
          version {
            hero {
              file
            }
            slug
          }
          vehicleConfig {
            slug
          }
        }
        vnCount: versions(vehicles: { categoryIn: "VN" }) {
          availableUnits
        }
        km0Count: versions(vehicles: { categoryIn: "Km0" }) {
          availableUnits
        }
        voCount: versions(vehicles: { categoryIn: "VO" }) {
          availableUnits
        }
      }
    }
  }
`

// Make-model catalog pages

export const browserModelsByBrandQuery = gql`
  query browserModelsByBrandQuery($vehicleType: [String!], $makeSlug: String) {
    models(
      make: { slugLike: $makeSlug }
      vehicleTypeIn: $vehicleType
      vehicles: { statusIn: "available" }
    ) {
      nodesCount
      nodes {
        name
        slug
        availableUnits
        vehicleType
        year
        vnUnits: publishableUnits(category: "vn")
        voUnits: publishableUnits(category: "vo")
        km0Units: publishableUnits(category: "km0")
        make {
          name
          slug
        }
        vehicles(first: 1) {
          uid
          version {
            slug
            hero {
              file
            }
          }
          vehicleConfig {
            slug
            hero {
              file
            }
          }
          hero {
            file
          }
        }
      }
    }
  }
`

export const browserCarMotoBrandsQuery = gql`
  query browserCarMotoBrandsQuery($makeSlug: String, $language: String) {
    carBrands: makes(
      sortBy: "name"
      vehicleTypeIn: ["car", "van"]
      slugLike: $makeSlug
      vehicles: { statusIn: "available" }
    ) {
      nodesCount
      nodes {
        name
        slug
        logo {
          file
        }
        metaTitle(language: $language)
        metaDescription(language: $language)
        publishableUnits(vehicleTypeIn: ["car", "van"], categoryIn: ["vn", "km0", "vo"])
        description(language: $language)
        characteristic {
          description(language: $language)
        }
      }
    }
    motoBrands: makes(
      sortBy: "name"
      vehicleTypeLike: "motorbike"
      slugLike: $makeSlug
      vehicles: { statusIn: "available" }
    ) {
      nodesCount
      nodes {
        name
        slug
        logo {
          file
        }
        metaTitle(language: $language)
        metaDescription(language: $language)
        publishableUnits(vehicleType: "motorbike")
        description
        characteristic {
          description(language: $language)
        }
      }
    }
  }
`

export const browserBrandModelQuery = gql`
  query browserBrandModelQuery($makeSlug: String, $modelName: String, $language: String) {
    model: models(make: { slugLike: $makeSlug }, nameLike: $modelName) {
      nodes {
        name
        slug
        characteristic: generalCharacteristic {
          description(language: $language)
        }
        description(language: $language)
        metaTitle(language: $language)
        metaDescription(language: $language)
        versions {
          bodywork
        }
      }
    }
  }
`

export const browserMakeQuery = gql`
  query browserMakeQuery($makeSlug: String, $page: Int, $financialEntityName: String) {
    makeCars: vehicles(make: { slugLike: $makeSlug }) {
      nodesCount
    }

    voCars: vehicles(
      page: $page
      perPage: 18
      categoryIn: "VO"
      make: { slugLike: $makeSlug }
      sortByFinancingPrice: "asc"
      version: { vehicles: { categoryIn: "VO" } }
    ) {
      nodes {
        uid
        useCases
        salesPricing(financialEntityName: $financialEntityName)
        category
        hero {
          file
        }
        make {
          name
          slug
        }
        model {
          name
          slug
        }
        version {
          name
          slug
          environmentalLabel
        }
        vehicleConfig {
          specs
          slug
          hero {
            file
          }
        }
        kilometres
        registrationDate
        manufacturerRef
      }
      nodesCount
    }

    km0Cars: vehicles(
      page: $page
      perPage: 18
      categoryIn: "Km0"
      make: { slugLike: $makeSlug }
      sortByFinancingPrice: "asc"
    ) {
      nodes {
        uid
        useCases
        salesPricing(financialEntityName: $financialEntityName)
        hero {
          file
        }
        category
        make {
          name
          slug
        }
        model {
          name
          slug
        }
        version {
          name
          slug
          environmentalLabel
        }
        vehicleConfig {
          specs

          slug

          hero {
            file
          }
        }
        kilometres
        registrationDate
        manufacturerRef
      }
      nodesCount
    }

    vnCars: models(
      page: $page
      perPage: 18
      vehicleTypeIn: ["car", "van"]
      make: { slugLike: $makeSlug }
      vehicles: { categoryIn: "VN" }
      sortByPrice: "asc"
    ) {
      nodes {
        useCases
        name
        slug
        salesVnCheapestPrice(financialEntityName: $financialEntityName)
        environmentalLabels(category: "vn")
        useCases
        make {
          name
          slug
        }
        vehicles(categoryIn: "VN", sortByPrice: "asc", first: 1) {
          category
          vehicleConfig {
            hero {
              file
            }
          }
        }
      }
      nodesCount
    }

    voModelsCount: models(
      make: { slugLike: $makeSlug }
      versions: { vehicles: { categoryIn: "VO" } }
    ) {
      nodes {
        name
      }
    }

    km0ModelsCount: models(
      make: { slugLike: $makeSlug }
      versions: { vehicles: { categoryIn: "Km0" } }
    ) {
      nodes {
        name
      }
    }

    vnModelsCount: models(
      make: { slugLike: $makeSlug }
      versions: { vehicles: { categoryIn: "VN" } }
    ) {
      nodes {
        name
      }
    }
  }
`

export const browserModelQuery = gql`
  query browserModelQuery(
    $makeSlug: String
    $modelName: String
    $page: Int
    $financialEntityName: String
  ) {
    modelCars: models(make: { slugLike: $makeSlug }, nameLike: $modelName) {
      nodesCount
    }

    voCars: vehicles(
      page: $page
      perPage: 18
      categoryIn: "VO"
      sortByFinancingPrice: "asc"
      make: { slugLike: $makeSlug }
      model: { nameLike: $modelName }
    ) {
      nodes {
        uid
        useCases
        salesPricing(financialEntityName: $financialEntityName)
        category
        useCases
        hero {
          file
        }
        make {
          name
          slug
        }
        model {
          name
          slug
        }
        version {
          name
          slug
          environmentalLabel
        }
        vehicleConfig {
          specs

          slug

          hero {
            file
          }
        }
        kilometres
        registrationDate
      }
      nodesCount
    }

    km0Cars: vehicles(
      page: $page
      perPage: 18
      categoryIn: "Km0"
      sortByFinancingPrice: "asc"
      make: { slugLike: $makeSlug }
      model: { nameLike: $modelName }
    ) {
      nodes {
        uid
        useCases
        salesPricing(financialEntityName: $financialEntityName)
        useCases
        hero {
          file
        }
        make {
          name
          slug
        }
        model {
          name
          slug
        }
        version {
          name
          slug
          environmentalLabel
        }
        category
        vehicleConfig {
          specs

          slug

          hero {
            file
          }
        }
        kilometres
        registrationDate
      }
      nodesCount
    }

    vnCars: models(
      page: $page
      perPage: 18
      vehicleTypeIn: ["car", "van"]
      make: { slugLike: $makeSlug }
      nameLike: $modelName
      vehicles: { categoryIn: "VN" }
      sortByPrice: "asc"
    ) {
      nodes {
        useCases
        name
        slug
        salesVnCheapestPrice(financialEntityName: $financialEntityName)
        environmentalLabels(category: "vn")
        useCases
        make {
          name
          slug
        }
        vehicles(categoryIn: "VN", sortByPrice: "asc", first: 1) {
          category
          vehicleConfig {
            hero {
              file
            }
          }
        }
      }
      nodesCount
    }

    voModelsCount: vehicles(
      model: { nameLike: $modelName }
      make: { slugLike: $makeSlug }
      categoryIn: "VO"
    ) {
      nodesCount
    }

    km0ModelsCount: vehicles(
      model: { nameLike: $modelName }
      make: { slugLike: $makeSlug }
      categoryIn: "Km0"
    ) {
      nodesCount
    }

    vnModelsCount: vehicles(
      make: { slugLike: $makeSlug }
      model: { vehicleTypeIn: ["car", "van"], nameLike: $modelName }
      version: { vehicles: { categoryIn: "VN" } }
      categoryIn: "VN"
    ) {
      nodesCount
    }
  }
`

export const browserModelSuggestionsQuery = gql`
  query browserModelSuggestionsQuery($bodywork: String, $modelSlug: String) {
    suggestions: models(
      vehicleTypeIn: ["car", "van"]
      slugNlike: $modelSlug
      versions: { bodyworkLike: $bodywork }
      sortBy: "name"
    ) {
      nodes {
        make {
          name
          slug
        }
        name
        slug
      }
    }
  }
`

export default {
  // (home) Make-model form
  makes,
  models,
  modelsByMake,

  // Make-model catalog pages
  browserBrandModelQuery,
  browserCarMotoBrandsQuery,
  browserMakeQuery,
  browserModelQuery,
  browserModelsByBrandQuery,
  browserModelSuggestionsQuery,
}
